export const enum IntelliFormPackagePayerEnum {
  COMPANY = 'company',
  CUSTOMER = 'customer',
}

export const enum IntelliFormLeadPackageStatusEnum {
  NEW = 'new',
  STARTED = 'started',
  FINISHED = 'finished',
  PAID = 'paid',
  SUBMITTED = 'submitted',
}

export const enum IntelliFormLeadDocumentStatusEnum {
  NEW = 'new',
  INCOMPLETE = 'incomplete',
  COMPLETED = 'completed',
  SUBMITTED = 'submitted',
}

export const enum IntelliFormFieldTypeEnum {
  BUTTON_FORM_FIELD = 'ButtonFormField',
  CHECKBOX_FORM_FIELD = 'CheckBoxFormField',
  RADIO_BUTTON_FORM_FIELD = 'RadioButtonFormField',
  CHOICE_FORM_FIELD = 'ChoiceFormField',
  COMBOBOX_FORM_FIELD = 'ComboBoxFormField',
  LIST_BOX_FORM_FIELD = 'ListBoxFormField',
  SIGNATURE_FORM_FIELD = 'SignatureFormField',
  TEXT_FORM_FIELD = 'TextFormField'
}

