<template>
  <div></div>
</template>

<script setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { onBeforeMount } from 'vue'
import { useAuth } from '@/store/Auth'

const authStore = useAuth()

onBeforeMount(() => {
;(function (w, d, s, o, f, js, fjs) {
  w[o] =
    w[o] ||
    function () {
      ;(w[o].q = w[o].q || []).push(arguments)
    }
  ;(js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0])
  js.id = o
  js.src = f
  js.async = 1
  fjs.parentNode.insertBefore(js, fjs)
})(
  window,
  document,
  'script',
  '_onramp_widget',
  'https://onboarding.intellikidsystems.com/api/embed_widget/static/onramp_inapp_sdk_v1.js',
)
_onramp_widget({
  organization: '5f84f4ca-d4e9-4c02-a926-ab5c3a31d61c',
  user: authStore.getters.user?.email,
  scheme: 'plaintext',
})
})
</script>
